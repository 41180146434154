// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyABxTp9DvompB9p6kNtzr0Q4nJoRKiDYi0',
    authDomain: 'qapp-285413.firebaseapp.com',
    databaseURL: 'https://qapp-285413.firebaseio.com',
    projectId: 'qapp-285413',
    storageBucket: 'qapp-285413.appspot.com',
    messagingSenderId: '918588276005',
    appId: '1:918588276005:web:81c6fb5fa05a6101c66ecc',
    measurementId: 'G-F0QLQCFMS6'
  },
  onesignal: {
    appId: 'c64d426b-2d55-4a56-a1b2-f4b19f4c345b',
    googleProjectNumber: '918588276005',
    restKey: 'ZjQyMzIzYTItZjVlOC00ZjdkLTlmMWItNmU5OTliZDk5NmI4'
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: 'Bs',
    code: 'BOB'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
